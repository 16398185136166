"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatasetType = void 0;
/**
 * An enum holding each predefined dataset type like Rubik, Chess etc
 */
var DatasetType;
(function (DatasetType) {
    DatasetType[DatasetType["Rubik"] = 0] = "Rubik";
    DatasetType[DatasetType["Chess"] = 1] = "Chess";
    DatasetType[DatasetType["Neural"] = 2] = "Neural";
    DatasetType[DatasetType["Go"] = 3] = "Go";
    DatasetType[DatasetType["Test"] = 4] = "Test";
    DatasetType[DatasetType["Story"] = 5] = "Story";
    DatasetType[DatasetType["Cohort_Analysis"] = 6] = "Cohort_Analysis";
    DatasetType[DatasetType["Chem"] = 7] = "Chem";
    DatasetType[DatasetType["Trrack"] = 8] = "Trrack";
    DatasetType[DatasetType["None"] = 9] = "None";
})(DatasetType = exports.DatasetType || (exports.DatasetType = {}));
